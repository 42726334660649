import { Cgt1Component } from './pages/cgt1/cgt1.component';
import { SurveyModule } from './pages/survey/survey.module';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthComponent } from './pages/auth/auth.component';
import { SuperauthComponent } from './pages/superadmin/superauth/superauth.component';
import { SuperlayoutComponent } from './pages/superadmin/layout/superlayout.component';


export const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    pathMatch: 'full'
  },
  {
    path: 'auth',
    component: AuthComponent,
    pathMatch: 'full'
  },
  {
    path: 'super',
    component: SuperauthComponent,
    pathMatch: 'full'
  },

  /*Super Admin Routing*/

  {
    path: '',
    component: SuperlayoutComponent,
    data: {
      title: 'Angular Admin Template'
    },
    children: [
      {
        path: 'superdashboard',
        loadChildren: () => import('./pages/superadmin/dashboard/superdashboard.module').then(m => m.SuperDashboardModule),
        data: {
          title: 'Super Dashboard | Home'
        },
      },

      {
        path: 'Activebanks',
        loadChildren: () => import('./pages/superadmin/managebanks/managebanks.module').then(m => m.ManagebanksModule),
        data: {
          title: 'Super Dashboard | Home'
        },
      },

      {
        path: 'walletamount',
        loadChildren: () => import('./pages/superadmin/wallet-amount/wallet-amount.module').then(m => m.WalletAmountModule),
        data: {
          title: 'wallet Amount | Home'
        },
      },
      {
        path: 'addwalletamount',
        loadChildren: () => import('./pages/superadmin/add-wallet-amount/add-wallet-amount.module').then(m => m.AddWalletAmountModule),
        data: {
          title: 'wallet Amount | Home'
        },
      },
      {
        path: 'addDefinePrice',
        loadChildren: () => import('./pages/superadmin/add-define-price/add-define-price.module').then(m => m.AddDefinePriceModule),
        data: {
          title: 'Add Define Price | Home'
        },
      },
      {
        path: 'servicetransaction',
        loadChildren: () => import('./pages/superadmin/service-transaction/service-transaction.module').then(m => m.ServiceTransactionModule),
        data: {
          title: 'Service Transaction | Home'
        },
      },
      {
        path: 'wallettransaction',
        loadChildren: () => import('./pages/superadmin/wallet-transaction/wallet-transaction.module').then(m => m.WalletTransactionModule),
        data: {
          title: 'Wallet Transaction | Home'
        },
      },
    ]
  },

  /*Bank Routing */
  {
    path: '',
    component: LayoutComponent,
    data: {
      title: 'Angular Admin Template'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('../app/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
          title: 'Dashboard Page'
        },
      },
      {
        path: 'bank-details',
        loadChildren: () => import('../app/pages/bank/bank.module').then(m => m.BankModule),
        data: {
          title: 'users Page'
        },
      },
      {
        path: 'user-info',
        loadChildren: () => import('../app/pages/forms/userinfo/userinfo.module').then(m => m.UserinfoRoutingModule),
        data: {
          title: 'user info'
        },
      },
      {
        path: 'disbursed-customer',
        loadChildren: () => import('../app/pages/forms/userinfo-list/userInfo-list.module').then(m => m.UserinfoListModule),
        data: {
          title: 'user infoList'
        },
      },
      {
        path: 'customer',
        loadChildren: () => import('../app/pages/customer/customer.module').then(m => m.CustomerModule),
        data: {
          title: 'customer Page'
        },
      },
      {
        path: 'users',
        loadChildren: () => import('../app/pages/users/users.module').then(m => m.UsersModule),
        data: {
          title: 'users Page'
        },
      }
      ,
      {
        path: 'role',
        loadChildren: () => import('../app/pages/role/role.module').then(m => m.RoleModule),
        data: {
          title: 'role Page'
        },
      },
      {
        path: 'area',
        loadChildren: () => import('../app/pages/area-form/area-form.module').then(m => m.AreaFormModule),
        data: {
          title: 'Branch Page'
        },
      }
      ,
      {
        path: 'branch',
        loadChildren: () => import('../app/pages/branch/branch.module').then(m => m.BranchModule),
        data: {
          title: 'Branch Page'
        },
      },
      {
        path: 'survey',
        loadChildren: () => import('../app/pages/survey/survey.module').then(m => m.SurveyModule),
        data: {
          title: 'survey Page'
        },
      },
      {
        path: 'area-survey',
        loadChildren: () => import('../app/pages/areasurvey/areasurvey.module').then(m => m.AreaSurveyModule),
        data: {
          title: 'survey Page'
        },
      },
      {
        path: 'cgt',
        loadChildren: () => import('../app/pages/cgt/cgt.module').then(m => m.CgtModule),
        data: {
          title: 'CGT'
        },
      },
      {
        path: 'cgt1',
        loadChildren: () => import('../app/pages/cgt1/cgt1.module').then(m => m.Cgt1Module),
        data: {
          title: 'CGT1'
        },
      },


      {
        path: 'centers',
        loadChildren: () => import('../app/pages/centers/centers.module').then(m => m.CentersModule),
        data: {
          title: 'Center Page'
        },
      },
      {
        path: 'Groups',
        loadChildren: () => import('../app/pages/groups/groups/groups.module').then(m => m.GroupsModule),
        data: {
          title: 'Group Page'
        },
      }
      ,
      {
        path: 'loans',
        loadChildren: () => import('../app/pages/loan/loan.module').then(m => m.LoanModule),
        data: {
          title: 'Loans Page'
        },
      },
      {
        path: 'disbursement',
        loadChildren: () => import('../app/pages/loan/disbursement/disbursement.module').then(m => m.DisbursementModule),
        data: {
          title: 'Loans Page'
        },
      },
      {
        path: 'disbursed',
        loadChildren: () => import('../app/pages/disbursed/disbursed.module').then(m => m.DisbursedModule),
        data: {
          title: 'Loans Disbursed Page'
        },
      },
      {
        path: 'emi',
        loadChildren: () => import('../app/pages/loan/emi/emi.module').then(m => m.EmiModule),
        data: {
          title: 'Emi Page'
        },
      },
      {
        path: 'recovery',
        loadChildren: () => import('../app/pages/loan/recovery/recovery.module').then(m => m.RecoveryModule),
        data: {
          title: 'Recovery Page'
        },
      },

      {
        path: 'blc-approval',
        loadChildren: () => import('../app/pages/blc/blc.module').then(m => m.BlcModule),
        data: {
          title: 'Recovery Page'
        },


      }
      , {
        path: 'application-pdf',
        loadChildren: () => import('../app/pages/documents/applicationpdf/applicationpdf.module').then(m => m.ApplicationpdfModule),
        data: {
          title: 'Recovery Page'
        },


      },
      {
        path: 'collection',
        loadChildren: () => import('../app/pages/collection/collection.module').then(m => m.CollectionModule),
        data: {
          title: 'Collection Page'
        },
      },

      {
        path: 'reports',
        loadChildren: () => import('../app/pages/reports/reports.module').then(m => m.ReportsModule),
        data: {
          title: 'Reports Page'
        },
      },
      {
        path: 'daily-balance-report',
        loadChildren: () => import('../app/pages/reports/daily-balance-report/daily-balance-report.module').then(m => m.DailyBalanceReportModule),
        data: {
          title: 'Daily Balance Report Page'
        },
      },
      {
        path: 'collection-balance-sheet',
        loadChildren: () => import('../app/pages/reports/collection-balance-sheet/collection-balance-sheet.module').then(m => m.CollectionBalanceSheetModule),
        data: {
          title: 'Collection Balance Sheet Page'
        },
      },
      {
        path: 'collection-report',
        loadChildren: () => import('../app/pages/reports/collection-report/collection-report.module').then(m => m.CollectionReportModule),
        data: {
          title: 'Collection Report Page'
        },
      },
      {
        path: 'demand-report',
        loadChildren: () => import('../app/pages/reports/month-demand-report/month-demand-report.module').then(m => m.MonthDemandReportModule),
        data: {
          title: 'Demand Report Page'
        },
      },
      {
        path: 'loan-collection-report',
        loadChildren: () => import('../app/pages/reports/loan-collection-report/loan-collection-report.module').then(m => m.LoanCollectionReportModule),
        data: {
          title: 'Loan Collection Report Page'
        },
      },
      {
        path: 'od-report',
        loadChildren: () => import('../app/pages/reports/od-report/od-report.module').then(m => m.OdReportModule),
        data: {
          title: 'OD Report Page'
        },
      },
      {
        path: 'preclose-report',
        loadChildren: () => import('../app/pages/loan/preclose-report/preclose-report.module').then(m => m.PrecloseReportModule),
        data: {
          title: 'Preclose Page'
        },
      },
      {
        path: 'emi-approve',
        loadChildren: () => import('../app/pages/emiapprove/emiapprove.module').then(m => m.EmiapproveModule),
        data: {
          title: 'Collection Page'
        },
      },
      {
        path: 'collection-approver',
        loadChildren: () => import('../app/pages/collectionApprover/collectionapprover.module').then(m => m.CollectionApproverModule),
        data: {
          title: 'CollectionApprover Page'
        },
      },
      {
        path: 'rejected-customer',
        loadChildren: () => import('../app/pages/rejectedCustomer/rejectedCustomer.module').then(m => m.RejectedCustomerModule),
        data: {
          title: 'RejectedCustomer Page'
        },
      },
      {
        path: 'reconcilliation',
        loadChildren: () => import('../app/pages/reconcilliation/reconcilliation.module').then(m => m.ReconcilliationModule),
        data: {
          title: 'Reconcilliation Page'
        },
      },
      {
        path: 'searchcustomer',
        loadChildren: () => import('../app/pages/searchcustomer/searchcustomer.module').then(m => m.SearchcustomerModule),
        data: {
          title: 'searchcustomer Page'
        },
      },
      {
        path: 'GroupMapping',
        loadChildren: () => import('../app/pages/group-mapping/group-mapping.module').then(m => m.GroupMappingModule),
        data: {
          title: 'GroupMapping Page'
        },
      },
      {
        path: 'highmark',
        loadChildren: () => import('../app/pages/highMark/highmark.module').then(m => m.HighmarkModule),
        data: {
          title: 'High Mark Page'
        },
      },
      {
        path: 'highmarkcustomer',
        loadChildren: () => import('../app/pages/highMarkCustomer/highmarkcustomer.module').then(m => m.HighmarkcustomerModule),
        data: {
          title: 'High Mark Page'
        },
      },
      {
        path: 'changeofficer',
        loadChildren: () => import('../app/pages/changeofficer/changeofficer.module').then(m => m.changeofficerModule),
        data: {
          title: 'Change Officer'
        },
      },
      {
        path: 'sendreceipt',
        loadChildren: () => import('../app/pages/sendreceipt/sendreceipt.module').then(m => m.SendreceiptModule),
        data: {
          title: 'Send Receipt'
        },
      },
      {
        path: 'delete-disbursmentcollection',
        loadChildren: () => import('../app/pages/delete-disbursmentcollection/delete-disbursmentcollection.module').then(m => m.DeleteDisbursmentcollectionModule),
        data: {
          title: 'Delete Disbursmentcollection'
        },
      },
      {
        path: 'delete-collection',
        loadChildren: () => import('../app/pages/delete-collection/delete-collection.module').then(m => m.DeleteCollectionModule),
        data: {
          title: 'Delete Collection'
        },
      }


    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
