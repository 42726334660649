import { Component, OnInit } from '@angular/core';
import { UsersService } from './../../services/users.service';
// import { CentersService } from './../../services/centers.service';
import { environment } from 'src/environments/environment.prod';
// import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { LocalStorageService } from 'angular-web-storage';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';

@Component({
  selector: 'app-searchcustomer',
  templateUrl: './searchcustomer.component.html',
  styleUrls: ['./searchcustomer.component.scss']
})
export class SearchcustomerComponent implements OnInit {
  searchForm: FormGroup;
  sessiondata: any;
  users:any[]=[];
  centers:any[]=[];
  constructor(private fb: FormBuilder, private http: HttpClient, 
    private usersService: UsersService,
    // private CenterService: CentersService,
    private session: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.sessiondata = this.session.get(environment.userSession);
    // this.loadCenter();
    this.searchForm = this.fb.group({
      searchTerm: ['']
    });
  }
  // loadCenter(): void {
  //   this.CenterService._get_centers({  bank_id: this.sessiondata.bank_id  }).subscribe(data => {
  //     this.centers = data;
  //   });
  // }
  loadUsers(searchTerm: string = ''): void {
    const params = { 
      bank_id: this.sessiondata.bank_id,
      searchTerm: searchTerm,
    };
    console.log('Search Term:', params);
    // console.log('Bank id',this.sessiondata.bank_id);
    this.usersService._searchuser(params).subscribe(data => {
      this.users = data;
      console.log('userdata',this.users);

    });
  }

  deleteUser(row) {
    if (confirm("Are you sure! You want to Delete This record.?")) {
      window.location.reload();
    }
  }

  onSearch(): void {
    const searchTerm = this.searchForm.value.searchTerm;
    this.loadUsers(searchTerm);
  }
}
