
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, of, Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private REST_API_SERVER = environment.api;

  private GET = this.REST_API_SERVER + 'Customer/get_customer_details';

  // New EMI List
  private GET_REJECTED = this.REST_API_SERVER + 'Customer/GetRejectedCustomer';
  private REVERT_NEW = this.REST_API_SERVER + 'Customer/revertAsNew';
  // private REJECT_EMI = this.REST_API_SERVER + 'welcome/rejectEmi';

  constructor(private httpClient: HttpClient) { }

  // EMI LIST for approve
  get_rejected_customer(data): Observable<String> {
    return this.httpClient.post<String>(`${this.GET_REJECTED}`, data).pipe(
      catchError(this.handleError)
    );
  }
  revert_new(data): Observable<String> {
    return this.httpClient.post<String>(`${this.REVERT_NEW}`, data).pipe(
      catchError(this.handleError)
    );
  }

  // reject_Emi(data): Observable<String> {
  //   return this.httpClient.post<String>(`${this.REJECT_EMI}`, data).pipe(
  //     catchError(this.handleError)
  //   );
  // }

  /************/

  _get_customer_data(data): Observable<String> {
    return this.httpClient.post<String>(`${this.GET}`, data).pipe(
      catchError(this.handleError)
    );
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}