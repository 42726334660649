var hostname=window.location.hostname;
if (hostname == 'localhost'){
  var api='https://finance.vilasoft.in/api/';
  var portal='https://finance.vilasoft.in/';
  var uploads = 'https://finance.vilasoft.in/uploads/Documents/';
}else{
  var api='/secure/api/';
  var portal='/secure/';
  var uploads = '/secure/uploads/Documents/';
}
export const environment = {
  api: api,
  portal: portal,
  uploads: uploads,
  userSession: 'userSession',
  SessionTime: 3600,
  production: true
};

// mfconnect
// https://mfconnect.vilasoft.in/

// export const environment = {
//   api: 'https://mfinbase.vilasoft.in/api/',
//   portal: 'https://mfinbase.vilasoft.in/',
//   uploads: 'https://mfinbase.vilasoft.in/uploads/Documents/',
//   userSession: 'userSession',
//   SessionTime: 3600,
//   production: true
// };

// export const environment = {
//   api: 'https://amplapi.vilasoft.in//api/',
//   portal: 'https://amplapi.vilasoft.in//',
//   uploads: 'https://amplapi.vilasoft.in//uploads/Documents/',
//   userSession: 'userSession',
//   SessionTime: 3600,
//   production: true
// };

//Localhost:
// export const environment = {
//   api: 'http://localhost/portal/api/',
//   portal: 'http://localhost/portal/',
//   uploads: 'http://localhost/portal/uploads/Documents/',
//   userSession: 'userSession',
//   SessionTime: 3600,
//   production: true
// };

// swarupfin
// https://swarupfin.vilasoft.in/

// export const environment = {
//   api: 'https://swarupapi.vilasoft.in/api/',
//   portal: 'https://swarupapi.vilasoft.in/',
//   uploads: 'https://swarupapi.vilasoft.in/uploads/Documents/',
//   userSession: 'userSession',
//   SessionTime: 3600,
//   production: true
// };

// export const environment = {
//   api: 'https://finance.vilasoft.in/api/',
//   portal: 'https://finance.vilasoft.in/',
//   uploads: 'https://finance.vilasoft.in/uploads/Documents/',
//   userSession: 'userSession',
//   SessionTime: 3600,
//   production: true
// };

// mfconnecttest
// https://mfconnecttest.vilasoft.in/

// export const environment = {
//   api: 'https://mfinbaseuat.vilasoft.in/api/',
//   portal: 'https://mfinbaseuat.vilasoft.in/',
//   uploads: 'https://mfinbaseuat.vilasoft.in/uploads/Documents/',
//   userSession: 'userSession',
//   SessionTime: 3600,
//   production: true
// };