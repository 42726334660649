import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchcustomerComponent } from './searchcustomer.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

const routes: Routes = [
  { path: '', component: SearchcustomerComponent }
];

@NgModule({
  declarations: [SearchcustomerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)
  ]
})
export class SearchcustomerModule { }
