<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Search Customer</title>
    <!-- Bootstrap CSS -->
    <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet">
    <style>
        body {
            font-family: Arial, sans-serif;
            margin: 20px;
        }
        .search-container {
            margin-bottom: 20px;
        }
        table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
        }
        th, td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: left;
        }
        th {
            background-color: #f2f2f2;
        }
    </style>
</head>
<body>
    <div class="container">
        <div class="search-container">
            <div class="row">
                <div class="col">
                    <h2>Search Customer</h2>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <form [formGroup]="searchForm" (ngSubmit)="onSearch()" class="form-inline">
                        <div class="row">
                            <div class="col">
                                <input type="text" id="searchTerm" formControlName="searchTerm" placeholder="Search by Name/MobNo./PanNo/UidNo/RationNo/VoterId " class="form-control mr-2">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <button type="submit" class="btn btn-primary">Search</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label for="centerSelect">Select Center:</label>
                        <select id="centerSelect" class="form-control">
                            <option value="">-- Select Center --</option>
                            <option *ngFor="let user of centers" [value]="user.center_id">{{ user.center_name }}</option>
                        </select>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="result-container">
            <h2>Customer Details</h2>
            <table id="customerTable" class="table table-striped">
                <thead class="thead-light">
                    <tr>
                        <th>Applicant Name</th>
                        <th>Mobile No</th>
                        <th>Center Name</th>
                        <th>CGT Verified </th>
                        <th>Approved Status</th>
                        <th>Approved By</th>
                        <th>BLC Verified</th>
                        <th>Disbursement Amount</th>
                        <!-- <th>Action</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of users">
                        <td>{{ user.applicant_name }} {{ user.applicant_father_name}}</td>
                        <td>{{ user.applicant_mob_no }}</td>
                        <td>{{user.center_name}}</td>
                        <td>
                            <ng-container *ngIf="user.is_cgt_verfied == 0; else yesBlock">
                                No
                            </ng-container>
                            <ng-template #yesBlock>
                                Yes
                            </ng-template>
                        </td>
                        <!-- <td>{{ user.approved_status }}</td> -->
                        <td>
                            <ng-container *ngIf="user.approved_status == 0; else otherBlock">
                                Pending
                            </ng-container>
                            <ng-template #otherBlock>
                                <ng-container *ngIf="user.approved_status == 1; else rejectedBlock">
                                    Approved
                                </ng-container>
                                <ng-template #rejectedBlock>
                                    Rejected
                                </ng-template>
                            </ng-template>
                        </td>
                        <td>{{ user.employee_first_name }}</td> 
                        <td>
                            <ng-container *ngIf="user.is_blc_verfied == 0; else yesBlock">
                                No
                            </ng-container>
                            <ng-template #yesBlock>
                                Yes
                            </ng-template>
                        </td>
                        
                        <td>{{ user.total_loan_amount }}</td>
                        <!-- <td>
                            <button type="button" class="btn btn-danger" (click)="deleteUser(user)"> Delete</button>
                        </td> -->
                    </tr>
                </tbody>
                
            </table>
        </div>
    </div>

    <!-- Bootstrap JS and dependencies (optional if needed for Bootstrap components) -->
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.slim.min.js"></script>
</body>
</html>
