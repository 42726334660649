import { Users } from './../model/users';
import { ConsumerHighmark } from './../model/consumerHighmark';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import {  throwError, of, Observable  } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private REST_API_SERVER = environment.api;
  private ADD_USERS = this.REST_API_SERVER + 'Users/Add';
  private ADD_USERINFO = this.REST_API_SERVER + 'Users/UserInfo';
  private UPLOAD_PHOTO = this.REST_API_SERVER + 'Users/upload_photo';
  private ADD_UPDATE = this.REST_API_SERVER + 'Users/update';
  private GET_USERS_TABLE = this.REST_API_SERVER + 'Users/LoadTable';
  private GET_DATA = this.REST_API_SERVER + 'Users/viewdata';
  private FILTER_DATA = this.REST_API_SERVER + 'Users/filterdata';
  private GET_COLLECTION_BY_USER_URL = this.REST_API_SERVER + 'Users/GetCollectionByUser';
  private GET_USER_BY_CENTER = this.REST_API_SERVER + 'Users/Getuserbycenter';
  private SAVE_FORM_DATA =this.REST_API_SERVER + 'Users/saveformdata';
  private GET_USERS_INFO = this.REST_API_SERVER + 'Users/UserInfoList';
  private GET_USERS_DETAILS = this.REST_API_SERVER + 'Users/get_user_details';
  private GET_SEARCH_USERS = this.REST_API_SERVER + 'Users/get_search_users';
  private GET_SEARCH_DISBURSMENT = this.REST_API_SERVER + 'Users/search_disbursment';
  private GET_SEARCH_COLLECTION = this.REST_API_SERVER + 'Users/search_collection';
  private DELETE_USERS = this.REST_API_SERVER + 'Users/delete';
  private GET_DISBURSED_DATA = this.REST_API_SERVER + 'Users/DisbursedCustomer';
  private DELETE_DISBURSED_DATA = this.REST_API_SERVER + 'Users/DeleteDisbursedCustomer';
  private DELETE_COLLECTION_DATA = this.REST_API_SERVER + 'Users/Deletecollection';
  constructor(private httpClient: HttpClient) { }

  /* Manage Role Operations */
   _upload_photo(form, id): Observable<Users>{
    const formData: any = new FormData();
    formData.append('profile', form.get('profile').value);
    formData.append('employee_id', id);
    return this.httpClient.post<Users>(`${this.UPLOAD_PHOTO}`, formData).pipe(
      catchError(this.handleError)
    );
  }
  getUserbycenter(data): Observable<Users[]>{
    return this.httpClient.post<Users[]>(`${this.GET_USER_BY_CENTER}`,data).pipe(
      catchError(this.handleError)
    );
  }
  _add_user(form: Users): Observable<Users>{
    return this.httpClient.post<Users>(`${this.ADD_USERS}`, form);
  }
  _add_userInfo(form: any): Observable<any>{
    return this.httpClient.post<any>(`${this.ADD_USERINFO}`, form);
  }
  _update_branch(add: Users): Observable<Users>{
    return this.httpClient.post<Users>(`${this.ADD_UPDATE}`, add).pipe(
      catchError(this.handleError)
    );
  }
  _get_users(data): Observable<Users[]>{
    return this.httpClient.post<Users[]>(`${this.GET_USERS_TABLE}`,data).pipe(
      catchError(this.handleError)
    );
  }
  viewdata(data): Observable<Users[]>{
      return this.httpClient.post<Users[]>(`${this.GET_DATA}`,data).pipe(
        catchError(this.handleError)
      );
    }
  filterdata(data): Observable<Users[]>{
    return this.httpClient.post<Users[]>(`${this.FILTER_DATA}`,data).pipe(
      catchError(this.handleError)
    );
  }
  deleteUser(data): Observable<Users[]>{
  return this.httpClient.post<Users[]>(`${this.DELETE_DISBURSED_DATA}`,data).pipe(
    catchError(this.handleError)
  );
  }
  deleteCollection(data): Observable<Users[]>{
    return this.httpClient.post<Users[]>(`${this.DELETE_COLLECTION_DATA}`,data).pipe(
      catchError(this.handleError)
    );
    }
  _searchuser(data): Observable<Users[]>{
    return this.httpClient.post<Users[]>(`${this.GET_SEARCH_USERS}`,data).pipe(
      catchError(this.handleError)
    );
  }
  _searchdisbursment(data): Observable<Users[]>{
    return this.httpClient.post<Users[]>(`${this.GET_SEARCH_DISBURSMENT}`,data).pipe(
      catchError(this.handleError)
    );
  }
  _searchcollection(data): Observable<Users[]>{
    return this.httpClient.post<Users[]>(`${this.GET_SEARCH_COLLECTION}`,data).pipe(
      catchError(this.handleError)
    );
  }
  submitForm(formData: FormData): Observable<any> {
    return this.httpClient.post<Users[]>(`${this.SAVE_FORM_DATA}`, formData);
  }
  getCollectionByUser(data): Observable<Users[]>{
    return this.httpClient.post<Users[]>(`${this.GET_COLLECTION_BY_USER_URL}`,data).pipe(
      catchError(this.handleError)
    );
  }

  _get_usersInfoList(data): Observable<ConsumerHighmark[]>{
    return this.httpClient.post<ConsumerHighmark[]>(`${this.GET_USERS_INFO}`,data).pipe(
      catchError(this.handleError)
    );
  }
  _get_user_details(data:{}): Observable<Users>{
    return this.httpClient.post<Users>(`${this.GET_USERS_DETAILS}`, data).pipe(
      catchError(this.handleError)
    );
  }
  get_disbursed_data(data): Observable<any>{
    return this.httpClient.post<any>(`${this.GET_DISBURSED_DATA}`,data).pipe(
      catchError(this.handleError)
    );
  }
  _delete_branch(data): Observable<any>{
    return this.httpClient.post<any>(`${this.DELETE_USERS}`, data).pipe(
      catchError(this.handleError)
    );
  }
  
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
