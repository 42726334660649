import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { ChangeDetectorRef } from '@angular/core';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  SessionData: any;
  
  Role:any;
  allowedPages: string[] = [];
  checkboxOptions = [
    { id: 1, name: "Edit Bank Details",route:"/bank-details" },
    { id: 2, name: "Manage Role",route:"/role" },
    { id: 3, name: "Manage Area",route:"/area" },
    { id: 4, name: "Manage Branch",route:"/branch" },
    { id: 5, name: "Assign Area Surveys",route:"/survey" },
    { id: 6, name: "View Area Surveys",route:"/area-survey" },
    { id: 7, name: "View Center",route:"/centers" },
    { id: 8, name: "View Groups",route:"/Groups" },
    { id: 9, name: "Groups Mapping",route:"/" },
    { id: 10, name: "View CGT",route:"/cgt" },
    { id: 11, name: "View CGT1",route:"/cgt1" },
    { id: 12, name: "BLC",route:"/blc-approval" },
    { id: 13, name: "SBND" ,route:"/disbursement"},
    { id: 14, name: "View EMI",route:"/emi" },
    { id: 15, name: "Disbursed" ,route:"/disbursed"},
    { id: 16, name: "Preclose" ,route:"/preclose-report"},
    { id: 17, name: "Approve Transaction",route:"/recovery" },
    { id: 18, name: "View Customer Details",route:"/customer" },
    { id: 19, name: "Application Form" ,route:"/"},
    { id: 20, name: "Disburse Document",route:"/" },
    { id: 21, name: "Collection Sheet",route:"/collection" },
    { id: 22, name: "Daily Balance Report",route:"/daily-balance-report" },
    { id: 23, name: "Demand Report",route:"/demand-report" },
    { id: 24, name: "Loan Collection Report",route:"/loan-collection-report" },
    { id: 25, name: "Collection Report",route:"/collection-report" },
    { id: 26, name: "OD Report",route:"/od-report" },
    { id: 27, name: "Emi Approve",route:"/emi-approve" },
    { id: 28, name: "Collection",route:"/collection-approver" },
    { id: 29, name: "Rejected Customer",route:"/rejected-customer" },
    { id: 30, name: "Reconcilliation",route:"/reconcilliation" },
    { id: 31, name: "SearchCustomer",route:"/searchcustomer" },
    { id: 32, name: "Send Receipt",route:"/sendreceipt" },
    { id: 34, name: "System Customer",route:"/highmarkcustomer" },
    { id: 35, name: "Walking Customer",route:"/highmark" },
    { id: 36, name: "Delete Disbursment Collection",route:"/delete-disbursmentcollection" },
    { id: 41, name: "All Users",route:"/users" },
    { id: 42, name: "Change Officer",route:"/changeofficer" },
    { id: 43, name: "Assign FO/TE" },
    { id: 44, name: "Edit Group Leader"},
    { id: 45, name: "Delete Collection",route:"/delete-collection"}
  ];
  constructor(public local: LocalStorageService,private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.SessionData = this.local.get(environment.userSession);
    this.Role =  this.SessionData.role_code;
    this.allowedPages = this.SessionData.role_pages || [];
    console.log("role---"+this.Role);
    console.log(this.allowedPages);
    this.cdr.detectChanges();

  }

  hasAccess(...pageIds: string[]): boolean {
    // Check if all provided page IDs are allowed for the user
    
    return pageIds.every(pageId => this.allowedPages.includes(pageId));
  }
}
